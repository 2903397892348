<template>
  <v-app>
    <transition name="fade">
      <div>
        <router-view></router-view>
      </div>
    </transition>
  </v-app>
</template>

<script>
  import { interceptors } from '@/helpers/api'

export default {
  created() {
    interceptors((err) => {
      if(err.response.status === 401) {
        //Auth.remove()
        //revoke() clear local storage
        this.$store.commit('SET_USER', null);
        if(this.$route.name != 'login') {
          return this.$router.push({ name: 'login'})
        }
      }

      if(err.response.status === 500) {
        //Flash.setError(err.response.statusText)
        return this.$router.push({name: '500'})
      }

      if(err.response.status === 404) {
      //https://www.bountysource.com/issues/38142121-missing-param-for-named-route-notfound-expected-0-to-be-defined
        return this.$router.push({ name: '404', params: { '0': '404' } }) 
      }

    })

    //window.console.log("App.vue")

  },

  mounted() {
  }
}
</script>

<style>
  html { overflow-y: auto }
  .pointer {cursor: pointer;}
  .fade-enter-active, .fade-leave-active {
        transition-property: opacity;
        transition-duration: .25s;
  }

  .fade-enter-active {
      transition-delay: .25s;
  }

  .fade-enter, .fade-leave-active {
      opacity: 0
  }
</style>
